/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import getPropStylesFactory from 'lib/utils/getPropStylesFactory';
import getComponentStylesFactory from 'lib/utils/getComponentStylesFactory';
import getComponentThemeFactory from 'lib/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "sliderSwiper": {
    "content_size_default": "w-full mx px-0",
    "arrow_size_default": "w-8 xl:w-4 h-8 p-2 xl:p-0",
    "arrow_colors_default": "bg-gray-100 xl:bg-transparent",
    "arrow_variant_default": "hidden md:flex justify-center cursor-pointer z-10 absolute top-1/2 -translate-y-[150%] data-[direction=prev]:rotate-180 data-[direction=next]:right-6 data-[direction=prev]:left-6 xl:data-[direction=next]:-right-6 xl:data-[direction=prev]:-left-6 rounded-full xl:rounded-none [&.swiper-button-disabled]:hidden"
  },
  "header_colors_default": "",
  "header_size_default": "gap-1 mb-3",
  "header_variant_default": "flex flex-col",
  "header_title_colors_default": "",
  "header_title_size_default": "text-headline-3xs",
  "header_title_variant_default": "uppercase flex flex-row gap-1",
  "header_description_colors_default": "text-gray-700",
  "header_description_size_default": "text-input-sm mt-1",
  "header_description_variant_default": "",
  "colors_default": "",
  "size_default": "w-full",
  "variant_default": "flex flex-col"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "VideoSlider");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;